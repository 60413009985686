import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { checkIfSubmittable } from '../../utils/validation'
import CHRButton from '../components/button'
import ContentContainer from '../components/contentContainer'
import CHRInput from '../components/input'
import CHRText from '../components/typography'
import { ROUTES } from '../constants'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/joinsStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

interface ForgotPasswordProps {
  t: TFunction
  location: any
}

const ResetPassword = (props: ForgotPasswordProps) => {
  const classes = styles()
  const [isConfirming, setIsConfirming] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  const [password2Shown, setPassword2Shown] = useState(false)
  const [resetPasswordFields, setResetPasswordFields] = useState({
    code: '',
    email: '',
    password: '',
    confirmPassword: '',
  })

  const { t } = props
  const location = props?.location?.href

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }

  const togglePassword2Visiblity = () => {
    setPassword2Shown(!password2Shown)
  }

  useEffect(() => {
    if (location.includes('ConfirmationCode')) {
      const code = location.split('=')[1]
      if (code !== undefined || (code !== null && code)) {
        setResetPasswordFields({
          ...resetPasswordFields,
          code,
          email: window.localStorage.getItem('CHRemail') || '',
        })
        return
      }
    }
    navigate(ROUTES.forgotPassword)
  }, [])

  async function handleConfirmClick() {
    if (resetPasswordFields.password === resetPasswordFields.confirmPassword) {
      setIsConfirming(true)

      try {
        await Auth.forgotPasswordSubmit(
          resetPasswordFields.email,
          resetPasswordFields.code,
          resetPasswordFields.password
        )
        setIsConfirming(false)
        navigate(ROUTES.signIn)
      } catch (error) {
        setErrorText(error.message.replace('Username', 'email'))
        setIsConfirming(false)
      }
    } else {
      setErrorText(t('forgotPassword.passwordsNotMatched'))
    }
  }

  return (
    <Layout>
      <ContentContainer>
        <form className={classes.container}>
          <CHRText align="left">
            {t('forgotPassword.enterConfirmationCodeSent')}
          </CHRText>

          <p className={classes.resetError}>{errorText && errorText}</p>
          <CHRInput
            value={resetPasswordFields.code}
            onChange={event => {
              setResetPasswordFields({
                ...resetPasswordFields,
                code: event.target.value,
              })
            }}
            placeholder={t('forgotPassword.confirmationCode')}
            type="password"
            required
            onKeyPress={event =>
              !isConfirming && checkIfSubmittable(event, handleConfirmClick)
            }
            customStyles={`${classes.input} ${classes.hiddenInput}`}
          />
          <CHRInput
            value={resetPasswordFields.email}
            onChange={event => {
              setResetPasswordFields({
                ...resetPasswordFields,
                email: event.target.value,
              })
            }}
            autoComplete="new-password"
            placeholder={t('shared.emailAddress')}
            type="email"
            required
            onKeyPress={event =>
              !isConfirming && checkIfSubmittable(event, handleConfirmClick)
            }
            customStyles={classes.input}
          />
          <div className={classes.passwordField}>
            <CHRInput
              autoComplete="new-password"
              value={resetPasswordFields.password}
              onChange={event => {
                setResetPasswordFields({
                  ...resetPasswordFields,
                  password: event.target.value,
                })
              }}
              placeholder={t('shared.password')}
              type={passwordShown ? 'text' : 'password'}
              required
              onKeyPress={event =>
                !isConfirming && checkIfSubmittable(event, handleConfirmClick)
              }
              customStyles={classes.input}
            />
            <i
              className={classes.passVisibility}
              onClick={togglePasswordVisiblity}
            >
              {passwordShown ? eye : eyeSlash}
            </i>
          </div>
          <div className={classes.passwordField}>
            <CHRInput
              autoComplete="new-password"
              value={resetPasswordFields.confirmPassword}
              onChange={event => {
                setResetPasswordFields({
                  ...resetPasswordFields,
                  confirmPassword: event.target.value,
                })
              }}
              placeholder={t('dashboard.confirmPassword')}
              type={password2Shown ? 'text' : 'password'}
              required
              onKeyPress={event =>
                !isConfirming && checkIfSubmittable(event, handleConfirmClick)
              }
              customStyles={classes.input}
            />
            <i
              className={classes.passVisibility}
              onClick={togglePassword2Visiblity}
            >
              {password2Shown ? eye : eyeSlash}
            </i>
          </div>
          <CHRButton
            onClick={handleConfirmClick}
            label={t('forgotPassword.enterConfirmationCode')}
            isSubmitting={isConfirming}
            disabled={isConfirming}
            customStyle={classes.buttonStyles}
          />
        </form>
      </ContentContainer>
    </Layout>
  )
}

export default withTranslation()(ResetPassword)
